<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

import {
    comboMethods,
    notificationMethods,
} from "@/state/helpers";

/**
 * Form Combos component
 */
export default {
    page: {
        title: "Formulário de Combos do Sistema",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: {},
    props: ['editingCombo'],
    data() {
        return {
        form: {
            id: null,
            order: 1,
            name: '',
            description: '',
            price: '',
            promotional_price: '',
            installments: '',
            status: true,
        },
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
        };
    },
    validations: {
        form: {
            name: { required },
            description: { required },
            price: { required },
            installments: { required },
        },
    },    
    methods: {
        ...comboMethods,
        ...notificationMethods,
        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.save(this.form).then(combo => {
                    this.emitCadSuccess(combo);
                    this.emitCad(false);
                });
            }
        },
        emitCad(event) {
            this.$emit('newCad', event);
        },
        emitCadSuccess(event) {
            this.$emit('newCadSuccess', event);
        },
        appendForm(combo) {
            this.form = {...combo}; 
        }
    },
    watch: { 
        editingCombo: function(combo) {
            this.appendForm(combo.item)
        }
    }
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">       
    <div class="row mb-4">
        <div class="col">
            <label class="form-label" for="title">Nome <span class="required">*</span></label>
            <input
            id="name"
            v-model="form.name"
            type="text"
            class="form-control"
            placeholder="Digite o nome"
            :class="{
                'is-invalid': submitted && $v.form.name.$error,
            }"
            />
            <div
            v-if="submitted && $v.form.name.$error"
            class="invalid-feedback"
            >
                <span v-if="!$v.form.name.required"
                    >Por favor, digite o nome.</span
                >
            </div>
        </div>
    </div>    
    <div class="row mb-4">
        <div class="col">
            <label class="form-label" for="title">Descrição <span class="required">*</span></label>
            <textarea
                v-model="form.description"
                class="form-control"
                name="description"
                placeholder="Digite a descrição"
                :class="{
                    'is-invalid': submitted && $v.form.description.$error,
                }"
                ></textarea>
            <div
                v-if="submitted && $v.form.description.$error"
                class="invalid-feedback"
            >
            <span v-if="!$v.form.description.required"
                >Por favor, digite a descrição.</span
            >
            </div>
        </div>
    </div>  
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="title">Preço <span class="required">*</span></label>
            <input
            id="price"
            v-model="form.price"            
            v-mask="'###.###.###.###.###,##'"
            type="text"
            class="form-control"
            placeholder="Digite o preço"
            :class="{
                'is-invalid': submitted && $v.form.price.$error,
            }"
        />
        <div
          v-if="submitted && $v.form.price.$error"
          class="invalid-feedback"
        >
            <span v-if="!$v.form.price.required"
                >Por favor, digite o preço.</span
            >
        </div>
      </div>
    </div>   
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="title">Preço Promocional</label>
            <input
            id="promotional_price"
            v-model="form.promotional_price"
            v-mask="'###.###.###.###.###,##'"
            type="text"
            class="form-control"
            placeholder="Digite o preço promocional"
        />
      </div>
    </div>    
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="title">Parcelas <span class="required">*</span></label>
            <input
            id="installments"
            v-model="form.installments"
            type="number"
            class="form-control"
            placeholder="Digite a quantia de parcelas"
            :class="{
                'is-invalid': submitted && $v.form.installments.$error,
            }"
        />
        <div
          v-if="submitted && $v.form.installments.$error"
          class="invalid-feedback"
        >
            <span v-if="!$v.form.installments.required"
                >Por favor, digite a quantia de parcelas.</span
            >
        </div>
      </div>
    </div>   
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="title">Ordem</label>
            <input
            id="order"
            v-model="form.order"
            type="number"
            class="form-control"
            placeholder="Digite a ordem do comboo"
        />
      </div>
    </div>     
    <div class="mt-2 text-end">
        <b-button variant="light" @click="emitCad(false)">Fechar</b-button>
        <b-button type="submit" variant="info" class="ms-1"
          >Cadastrar</b-button
        >
    </div>
  </form>
</template>