<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import FormCombos from "@/components/forms/form-combos/index";
import FormPlans from "@/components/forms/form-plans/index";
import DropdownDefault from "@/components/dropdown-default";
import appConfig from "@/app.config";
import { mapState } from "vuex";

import {
    planMethods,
    comboMethods,
    notificationMethods,
} from "@/state/helpers";

const TablesEnum = {
  PLANOS: 'Plano',
  COMBOS: 'Combo',
};

/**
 * Admin Plans component
 */
export default {
  page: {
    title: "Planos e Combos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FormCombos,
    FormPlans,
    DropdownDefault
  },
  data() {
    return {
      TablesEnum,
      editingPlan: {},
      editingCombo: {},
      tableData: [],
      title: "Planos e Combos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Planos e Combos",
          active: true,
        },
      ],   
      showModal: false,
      showModala: true,
      tableHighlighted: TablesEnum.PLANOS,
      totalRows: 1,
      rows: 0,
      loading: true,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        position: "Posição",
        name: "Nome",
        description: "Descrição",
        price: "Preço",
        promotion: "Preço Promocional",
        installments: "Parcelamento",
        status: "Status",
        actions: "Ações",
      },    
    }
  },
  created() {
    this.loadAll();
  },
  computed: {   
    ...mapState("authfack", ["status"]),
    fields() {
      return [
        { key: "order", sortable: true, label: this.fieldAliases.position },
        { key: "name", sortable: true, label: this.fieldAliases.name },
        { key: "description", sortable: true, label: this.fieldAliases.description },
        { key: "price", sortable: true, label: this.fieldAliases.price },
        { key: "promotion", sortable: true, label: this.fieldAliases.promotion },
        { key: "installments", sortable: true, label: this.fieldAliases.installments },
        { key: "status", sortable: true, label: this.fieldAliases.status },
        { key: "actions", sortable: true, label: this.fieldAliases.actions }
      ];
    }
  },
  methods: {    
    ...planMethods,
    ...comboMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },

    changeTable(e) {
      this.tableHighlighted = e;
      this.loadAll();
    },
    
    async loadAll() {
      this.loading = true;
      let registros = [];
      if (this.tableHighlighted == TablesEnum.PLANOS) {        
        registros = await this.getAll();
      } else {
        registros = await this.comboGetAll();
      }
      this.tableData = registros.data;
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    edit(obj) {
      this.showModal = true;
      if (this.tableHighlighted == TablesEnum.PLANOS) {
        setTimeout(() => {
          this.editingPlan = obj;        
        }, 200);
      } else {        
        setTimeout(() => {
          this.editingCombo = obj;        
        }, 200);
      }
    },

    async exclude(obj) {      
      if (this.tableHighlighted == TablesEnum.PLANOS) {
        await this.delete(obj.id);
      } else {        
        await this.comboDelete(obj.id);
      }
      this.loadAll();
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :addText="'Adicionar Novo ' + this.tableHighlighted" @newCad="handleNewCad" />
    <div class="row">
      <div class="col-12">
        <div class="card">          
          <b-button-group size="lg">
            <b-button :variant="tableHighlighted == TablesEnum.PLANOS ? 'primary' : 'secondary'" v-on:click="changeTable(TablesEnum.PLANOS)">Planos</b-button>
            <b-button :variant="tableHighlighted == TablesEnum.COMBOS ? 'primary' : 'secondary'" v-on:click="changeTable(TablesEnum.COMBOS)">Combos</b-button>
          </b-button-group>
          <div class="card-body">
            <h4 class="card-title">{{tableHighlighted == TablesEnum.PLANOS ? 'Planos' : 'Combos'}}</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage" size="sm" :options="pageOptions"></b-form-select> Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Pesquisar: 
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>
              <b-table
                v-if="!loading"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <!-- Coluna "actions" personalizada -->
                <template #cell(status)="obj">
                  <div class="actions-cell">    
                    <span class="badge ms-1" :class="obj.item.status ? 'bg-success' : 'bg-warning'">
                      <h6 class="m-0">{{obj.item.status ? "Ativo" : "Inativo"}}</h6>
                    </span>                   
                  </div>
                </template>
                <template #cell(installments)="obj">
                  <div class="actions-cell">   
                      {{obj.item.installments ? obj.item.installments + 'x' : 'Sem parcelas'}}     
                  </div>
                </template>
                <template #cell(actions)="obj">
                  <div class="actions-cell">
                    <DropdownDefault @edit="edit(obj)" @exclude="exclude(obj)"></DropdownDefault>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <b-modal v-model="showModal" id="modal-dialog" :title="'Cadastro de ' + this.tableHighlighted" title-class="font-18" hide-footer>
      <FormPlans
        v-if="this.tableHighlighted == TablesEnum.PLANOS"
        :parent="this"
        :editingPlan="editingPlan"
        @newCad="handleNewCad"
        @newCadSuccess="loadAll"
      ></FormPlans>
      <FormCombos
        v-if="this.tableHighlighted == TablesEnum.COMBOS"
        :parent="this"
        :editingCombo="editingCombo"
        @newCad="handleNewCad"
        @newCadSuccess="loadAll"
      ></FormCombos>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
